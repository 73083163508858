<script setup lang="ts">
import { VueFinalModal } from "vue-final-modal";
import copier from "clipboard-copy";

const { $axios, $auth, $showToast } = useNuxtApp();
const { t } = useI18n();

function copy(val: string) {
  copier(val);
  $showToast(t("modal.credentials.copied"), "success");
}

const isReadonly = ref(true);
const seedIcon = computed(() => (isReadonly.value ? "edit" : "check"));

const form = ref({
  client_seed: "",
  server_seed_hashed: "",
  nonce: 0,
});

const newForm = ref({
  old_server_seed_hashed: "",
  old_server_seed: "",
});

function onBeforeOpen() {
  isReadonly.value = true;
  Object.assign(newForm.value, {
    old_server_seed_hashed: "",
    old_server_seed: "",
  });
  if ($auth.user?.seed) {
    Object.assign(form.value, {
      client_seed: $auth.user!.seed.client_seed,
      server_seed_hashed: $auth.user!.seed.server_seed_hashed,
      nonce: $auth.user!.seed.nonce,
    });

    $axios.$get("auth/me").then(({ response }) => {
      Object.assign($auth.user, response);

      Object.assign(form.value, {
        client_seed: $auth.user!.seed.client_seed,
        server_seed_hashed: $auth.user!.seed.server_seed_hashed,
        nonce: $auth.user!.seed.nonce,
      });
    });
  }
}

function onSubmit() {
  $axios.$post("fair/serverSeed", { client_seed: $auth.user!.seed.client_seed }).then(({ response }) => {
    Object.assign(newForm.value, {
      old_server_seed_hashed: response.old.server_seed_hashed,
      old_server_seed: response.old.server_seed,
    });
    form.value.client_seed = $auth.user!.seed.client_seed = response.new.client_seed;
    form.value.server_seed_hashed = $auth.user!.seed.server_seed_hashed = response.new.server_seed_hashed;
    form.value.nonce = $auth.user!.seed.nonce = response.new.nonce;
    isReadonly.value = true;
  });
}

function onToggleSeed() {
  if (isReadonly.value) {
    isReadonly.value = false;
    const el = document.querySelector("#cseed") as HTMLInputElement;
    if (el) el.focus();
  } else {
    if (!form.value.client_seed || form.value.client_seed.length < 4 || form.value.client_seed.length > 128) {
      return $showToast(t("modal.seed.invalid"), "error");
    }
    $axios.$patch("fair/clientSeed", { client_seed: form.value.client_seed }).then(({ response: { seed: response } }) => {
      isReadonly.value = true;
      form.value.nonce = response.nonce;
      form.value.client_seed = response.client_seed;
      form.value.server_seed_hashed = response.server_seed_hashed;

      if ($auth.user!.seed.client_seed !== response.client_seed) $showToast(t("modal.seed.changed"));

      $auth.user!.seed.client_seed = form.value.client_seed;
      $auth.user!.seed.nonce = form.value.nonce;
    });
  }
}
</script>

<template>
  <VueFinalModal name="user_seed" @before-open="onBeforeOpen">
    <ModalBase :title="$t('modal.fair.settings')" name="user_seed">
      <div class="modal-seed">
        <VInput id="cseed" :suffix-icon="seedIcon" :suffix-function="onToggleSeed" v-model="form.client_seed"
          input-class="pr-20" suffix-square :readonly="isReadonly" :label="$t('modal.fair.client_seed')" color="gray">
        </VInput>
        <VInput suffix-icon="copy" :suffix-function="() => copy(form.server_seed_hashed)"
          v-model="form.server_seed_hashed" input-class="pr-20" suffix-square readonly
          :label="$t('modal.fair.server_seed')" color="gray"></VInput>
        <VInput v-model="form.nonce" readonly :label="$t('modal.fair.nonce')" color="gray" />
        <template v-if="newForm.old_server_seed">
          <VInput suffix-icon="copy" :suffix-function="() => copy(newForm.old_server_seed_hashed)"
            v-model="newForm.old_server_seed_hashed" suffix-square input-class="pr-20" readonly
            :label="$t('modal.fair.server_seed_old')" color="gray"></VInput>
          <VInput suffix-icon="copy" :suffix-function="() => copy(newForm.old_server_seed)"
            v-model="newForm.old_server_seed" suffix-square readonly input-class="pr-20"
            :label="$t('modal.fair.server_seed_opened_old')" color="gray">
          </VInput>
        </template>
        <VButton size="lg" color="orange" class="modal-seed__submit" @click="onSubmit">
          {{ $t("modal.fair.change") }}
        </VButton>
      </div>
    </ModalBase>
  </VueFinalModal>
</template>

<style lang="scss">
.modal-seed {
  display: grid;
  gap: var(--padding);
  width: 540px;
  max-width: 100%;

  &__control {
    &+& {
      margin-top: 20px;
    }

    &-label {
      font-weight: 600;
      margin-bottom: 8px;
      font-size: 14px;
      color: #9e96ba;
      display: block;
    }

    &-input {
      position: relative;

      input {
        padding-right: 50px;
        text-overflow: ellipsis;
      }

      button {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        aspect-ratio: 1/1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #b6b0d7;
      }
    }
  }

  &__submit {
    width: 100%;
  }
}
</style>
